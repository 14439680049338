<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="类型" prop="type" >
        <a-select v-model="form.type" placeholder="请选择类型" >
          <a-select-option :key="0">周会员</a-select-option>
          <a-select-option :key="1">月会员</a-select-option>
          <a-select-option :key="2">年会员</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="每天单价" prop="unitPrice" >
        <a-input v-model="form.unitPrice" placeholder="请输入每天单价" />
      </a-form-model-item>
      <a-form-model-item label="ios内购产品id" prop="productId" >
        <a-input v-model="form.productId" placeholder="请输入ios内购产品id" />
      </a-form-model-item>



      <a-form-model-item label="总价" prop="totalPrice" >
        <a-input disabled v-model="form.totalPrice" placeholder="请输入总价" />
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMenu, addMenu, updateMenu } from '@/api/vip/menu'
import TagSelectOption from "@/components/TagSelect/TagSelectOption";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    TagSelectOption
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        unitPrice: null,

        productId: null,

        totalPrice: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '类型 0-周会员  1-月会员  2-年会员不能为空', trigger: 'change' }
        ],
        unitPrice: [
          { required: true, message: '每天单价不能为空' }
        ],
        totalPrice: [
          { required: true, message: '总价不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        unitPrice: null,
        productId: null,
        totalPrice: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMenu({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMenu(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMenu(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
